/* top bar */
.md-top-bar {
    .md-top-bar-checkbox {
        padding-top: 10px;
    }
    .md-top-bar-icons {
        margin-top: 5px;
    }
    .md-top-bar-icons,
    .md-top-bar-checkbox {
        display: inline-block;
    }
    .md-btn-group {
        margin-top: 4px;
    }
    .md-top-bar-actions-left {
        float: left;
        padding-left: 16px;
        .md-btn-group {
            margin-left: 8px;
        }
    }
    .md-top-bar-actions-right {
        float: right;
        padding-right: 16px;
        .md-btn-group {
            margin-right: 8px;
        }
    }
    .md-btn-small {
        padding: 2px 12px;
    }
}